body {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 2% 18% 20% 20% 20% 18% 2%;
  grid-template-rows: 50px 1fr auto 50px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  color: #444;
  letter-spacing: 0.75px;
  background: #fff;
}

main {
  grid-row: 2;
  grid-column-start: 2;
  grid-column-end: 7;
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.heading {
  color: #B75F3F;
}

.domain_name {
  text-align: center;
}

.float_left {
  float: left;
}

.why {
  font-size: 16px;
  width: 80%;
}

hr {
  width: 100%;
  height: 2px;
  float: left;
}

@media screen and (max-width: 800px) {
  .paragraph {
    float: left;
  }
}
.email_link {
  display: inline;
  font-size: 30px;
}

.underline {
  text-decoration: none;
  border-bottom: 3px solid #6495ed;
}

.normal {
  float: left;
  width: 355px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  background-color: #c6c6c6;
}

.all {
  float: left;
  width: 355px;
  margin-top: 5px;
  margin-bottom: 20px;
  background-color: #e0e0e0;
}

.all > p {
  font-size: 16px;
}

.normal > p {
  font-size: 16px;
}

.page-header {
  margin-left: 0;
}

article {
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
  margin-left: 0;
  margin-bottom: 20px;
}

.paragraph {
  width: 100%;
  max-width: 800px;
  text-align: left;
  text-justify: none;
  margin-right: 10px;
  margin-left: 0;
}

.prettyprint {
  font-size: 16px;
  width: 350px;
  background: #ffffff;
  float: left;
  margin-right: 15px;
}

.border {
  border: 1px solid black;
}

.lb-data .lb-caption {
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
}

pre {
  float: left;
}

.normal_li {
  font-size: 16px;
  font-weight: 400;
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0;
  list-style-position: inside;
  list-style-type: square;
}

.normal_ul {
  font-size: 16px;
  margin-bottom: 25px;
}

.makeColumn_400 {
  column-width: 400px;
  column-gap: 50px;
}

.makeColumn_1000 {
  column-width: 1000px;
  column-gap: 0;
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../gfonts/roboto-v20-latin-regular.woff2") format("woff2"), url("../gfonts/roboto-v20-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
.post {
  font-size: 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.h1,
h1 {
  font-size: 32px;
}

.h2,
h2 {
  font-size: 24px;
}

.h3,
h3 {
  font-size: 18px;
}

nav a {
  text-decoration: none;
  display: block;
  color: black;
  padding: 12px;
}

nav {
  height: 50px;
  grid-row: 1;
  grid-column: 1/8;
  position: sticky;
  top: 0px;
  width: 100%;
  background-color: #e3e4e6;
  z-index: 100;
}

.wrapnav {
  margin: 0 auto;
  width: 300px;
}

nav .home {
  padding-right: 10px;
  padding-left: 4px;
  padding-top: 0;
  padding-bottom: 0;
}

nav ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

nav li {
  display: block;
  float: left;
  position: relative;
  text-decoration: none;
}

nav li:hover,
nav li:focus-within {
  background: #f1c232;
  cursor: pointer;
}

nav li:focus-within a {
  outline: none;
}

nav ul li ul {
  background-color: #e3e4e6;
  visibility: hidden;
  min-width: 30px;
  position: absolute;
  left: 0;
  display: none;
}

nav ul li:hover > ul,
nav ul li:focus-within > ul,
nav ul li ul:hover,
nav ul li ul:focus {
  visibility: visible;
  display: block;
}

nav ul li ul li {
  clear: both;
  width: 100%;
  font-size: 16px;
  background-color: #e3e4e6;
  border-bottom: 1px rgb(184, 184, 184) solid;
  border-left: 1px rgb(184, 184, 184) solid;
  border-right: 1px rgb(184, 184, 184) solid;
}

nav ul li ul li:first-child {
  border-top: 1px rgb(184, 184, 184) solid;
}

nav ul li ul li a {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 4px;
  padding-left: 4px;
}

img {
  max-width: 100%;
  height: auto;
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-gallery-paragraph {
  max-width: 1000px;
  width: 70%;
  text-align: justify;
  text-justify: inter-word;
  margin-right: 10px;
}

.rounded_corners {
  border-radius: 10px;
}

figure {
  padding: 0;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 50px;
  margin-right: 25px;
}

figcaption {
  color: #222;
  margin: 5px;
  font-size: 16px;
}

.figure_standard {
  max-width: 350px;
  float: left;
}

.figure_large {
  max-width: 600px;
  max-height: 450px;
}

.banner {
  padding-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  border: solid 0 black;
  overflow: hidden;
}

.picture {
  border: 1px solid #CCCCCC;
  padding: 2px;
}

.caption {
  max-width: 350px;
  font-size: 16px;
  line-height: 14px;
  font-weight: 500;
  float: left;
  clear: both;
  margin-bottom: 10px;
  margin-right: 10px;
}

.external_link {
  height: 18px;
  width: 18px;
}

.footer_row {
  grid-row: 3;
  grid-column: 1/8;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  background-color: #2c2d33;
  background-image: linear-gradient(#4c4c4c, #3b3c45);
  margin-top: 25px;
}

.footer_column {
  width: 225px;
  margin-bottom: 5px;
  margin-top: 20px;
  padding-bottom: 20px;
  border: 0 solid white;
}

.footer_column img {
  margin-top: 8px;
}

.footer_column a {
  transition: none;
}

.footer_column a:link {
  color: #f1c232;
  text-decoration: none;
}

.footer_column a:visited {
  color: #9c9c9c;
  text-decoration: none;
}

.footer_column a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer_column a:active {
  color: #fff;
}

.footer_column li {
  font-size: 16px;
  font-weight: 400;
  list-style-type: none;
  margin-top: 20px;
}

ul .footer_label {
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
}

/* .footer_column img:hover {
background-color: #3B5998;
border-radius: 4px 4px 4px 4px;
}
 */
ul li img:hover {
  border-radius: 4px 4px 4px 4px;
}

ul li img:visited {
  border-radius: 4px 4px 4px 4px;
}

.sig {
  grid-row: 4;
  grid-column: 1/11;
  width: 100%;
  background-color: #3B3C45;
}

.content_title {
  margin-top: 25px;
  margin-left: 0;
}

.archive_list {
  position: relative;
  margin-bottom: 50px;
}

.article {
  border: 0 solid black;
  margin-bottom: 50px;
  margin-right: 0;
}

.article_title {
  margin-top: 25px;
  margin-bottom: 25px;
}

.preface {
  width: 80%;
}

.remove_indent p {
  font-size: 16px;
}

.post p {
  font-size: 100%;
}

.post {
  margin-bottom: 25px;
  width: 400px;
}

.btn {
  display: block;
  clear: both;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.btn_small {
  width: 75px;
  height: 30px;
  line-height: 2.2;
  font-size: 14px;
}

.btn_blue {
  background: #007BFF;
  color: white;
}

.btn_nav {
  display: inline-block;
  clear: both;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.parent {
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: start;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: 0;
  float: left;
}

.child {
  width: 300px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.rad5 {
  border-radius: 5px 5px 0 0;
}

.card {
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 300px;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
  background: linear-gradient(to right, #4290da 0%, #99c0fa 100%);
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_body {
  padding: 10px 5px 5px 5px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.blogcard {
  max-width: 600px;
  height: auto;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  background: linear-gradient(to right, #4290da 0%, #99c0fa 100%);
}

.blogcard_body {
  padding: 10px 5px 5px 5px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.blogcard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}