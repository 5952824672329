.footer_row {
    grid-row: 3;
    grid-column: 1 / 8;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    background-color: #2c2d33;
    background-image: linear-gradient(#4c4c4c, #3b3c45);
    margin-top: 25px;
}

.footer_column {
    width: 225px;
    margin-bottom: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    border: 0 solid white;
    
}

.footer_column img {
margin-top: 8px;

}

.footer_column a {
    transition: none;
}

.footer_column a:link {
    color: #f1c232;
    text-decoration: none;
}

.footer_column a:visited {
    color: #9c9c9c;
    text-decoration: none;
}

.footer_column a:hover {
    color: #fff;
    text-decoration: underline;
}

.footer_column a:active {
    color: #fff;
}

.footer_column li {
    font-size: 16px;
    font-weight: 400;
    list-style-type: none;
    margin-top: 20px;
}

ul .footer_label {
    font-size: 20px;
    color: white;
    margin-bottom: 30px;
}


/* .footer_column img:hover {
background-color: #3B5998;
border-radius: 4px 4px 4px 4px;
}
 */


ul li img:hover {
    border-radius: 4px 4px 4px 4px;
}

ul li img:visited {
    border-radius: 4px 4px 4px 4px;
}