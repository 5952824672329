.rad5 {
    border-radius: 5px 5px 0 0;
}

.card {
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 300px;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    background: linear-gradient(to right, #4290da 0%, #99c0fa 100%);
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_body {
    padding: 10px 5px 5px 5px;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}

.blogcard {
    max-width: 600px;
    height: auto;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
    background: linear-gradient(to right, #4290da 0%, #99c0fa 100%);
}

.blogcard_body {
    padding: 10px 5px 5px 5px;
    font-size: 16px;
    font-weight: 300;
    color: #fff;
}

.blogcard:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}