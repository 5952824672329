body {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 2% 18% 20% 20% 20% 18% 2%;
    grid-template-rows: 50px 1fr auto 50px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
    color: #444;
    letter-spacing: 0.75px;
    background: #fff;
}


main {
    grid-row: 2;
    grid-column-start: 2;
    grid-column-end: 7;
    width: 100%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}


.heading {
    color: #B75F3F;
}


.domain_name {
    text-align: center;
}

.float_left {
    float: left;
}


.why {
    font-size: 16px;
    width: 80%;
}

hr {
    width: 100%;
    height: 2px;
    float: left;
}

@media screen and (max-width: 800px) {
    .paragraph {
        float: left;
    }
}

.email_link {
    display: inline;
    font-size: 30px;
}

.underline {
    text-decoration: none;
    border-bottom: 3px solid #6495ed;
}

.normal {
    float: left;
    width: 355px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    background-color: #c6c6c6;
}

.all {
    float: left;
    width: 355px;
    margin-top: 5px;
    margin-bottom: 20px;
    background-color: #e0e0e0;
}

.all>p {
    font-size: 16px;
}

.normal>p {
    font-size: 16px;
}

.page-header {
    margin-left: 0;
}

article {
    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 20px;
}

.paragraph {
    width: 100%;
    max-width: 800px;
    text-align: left;
    text-justify: none;
    margin-right: 10px;
    margin-left: 0;
}


.prettyprint {
    font-size: 16px;
    width: 350px;
    background: #ffffff;
    float: left;
    margin-right: 15px;
}

.border {
    border: 1px solid black;
}

.lb-data .lb-caption {
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
}

pre {
    float: left;
}


.normal_li {
    font-size: 16px;
    font-weight: 400;
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 0;
    list-style-position: inside;
    list-style-type: square;
}

.normal_ul {
    font-size: 16px;
    margin-bottom: 25px;
}

.makeColumn_400 {
    column-width: 400px;
    column-gap: 50px;
}


.makeColumn_1000 {
    column-width: 1000px;
    column-gap: 0;
}