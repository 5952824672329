.btn {
    display: block;
    clear: both;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
}

.btn_small {
    width: 75px;
    height: 30px;
    line-height: 2.2;
    font-size: 14px;
}

.btn_blue {
    background: #007BFF;
    color: white;
}

.btn_nav {
    display: inline-block;
    clear: both;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
}