

nav a {
  text-decoration: none;
  display: block;
  color: black;
  padding: 12px;
}

nav {
    height: 50px;
    grid-row: 1;
    grid-column: 1/8;
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #e3e4e6;
    z-index: 100;
}

.wrapnav {
margin: 0 auto;
width: 300px;
}

nav .home {
    padding-right: 10px;
    padding-left: 4px;
    padding-top: 0;
    padding-bottom: 0;
}

nav ul {
  list-style: none;
	margin: 0;
	padding-left: 0;
}

nav li {
	display: block;
	float: left;
	position: relative;
	text-decoration: none;
 
}
  
nav li:hover,
nav li:focus-within { 
    background: #f1c232;
    cursor: pointer;
}

nav li:focus-within a {
  outline: none;
}

nav ul li ul {
  background-color: #e3e4e6;
	visibility: hidden;
   min-width: 30px;
  position: absolute;
  left: 0;
  display: none; 
}

nav ul li:hover > ul,
nav ul li:focus-within > ul,
nav ul li ul:hover,
nav ul li ul:focus {
   visibility: visible;
   display: block;
}

nav ul li ul li {
clear: both;
width: 100%;
font-size: 16px;
background-color: #e3e4e6;
border-bottom: 1px rgb(184, 184, 184) solid;
border-left:  1px rgb(184, 184, 184) solid;
border-right: 1px rgb(184, 184, 184) solid;
}

nav ul li ul li:first-child {
border-top: 1px rgb(184, 184, 184) solid;
}

nav ul li ul li a {
padding-top: 8px;
padding-bottom: 8px;
padding-right: 4px;
padding-left: 4px;
}