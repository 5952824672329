img {
    max-width: 100%;
    height: auto;
}

img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-gallery-paragraph {
    max-width: 1000px;
    width: 70%;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 10px;
}

.rounded_corners {
    border-radius: 10px;
}

figure {
    padding: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 50px;
    margin-right: 25px;
}

figcaption {
    color: #222;
    margin: 5px;
    font-size: 16px;
}

.figure_standard {
    max-width: 350px;
    float: left;
}

.figure_large {
    max-width: 600px;
    max-height: 450px;
}

.banner {
    padding-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 0 black;
    overflow: hidden;
}

.picture {
    border: 1px solid #CCCCCC;
    padding: 2px
}

.caption {
    max-width: 350px;
    font-size: 16px;
    line-height: 14px;
    font-weight: 500;
    float: left;
    clear: both;
    margin-bottom: 10px;
    margin-right: 10px;
}

.external_link {
    height: 18px;
    width: 18px;
}