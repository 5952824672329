.parent {
    max-width: 1000px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 0;
    float: left;
}

.child {
    width: 300px;
    margin-bottom: 20px;
    margin-left: 20px;
}